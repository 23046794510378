
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VueSignaturePad from "vue-signature-pad";
import settings from "./backend/LocalSettings";
import VueResource from "vue-resource";
import locale from "element-ui/lib/locale/lang/en"
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import 'element-ui/lib/theme-chalk/display.css';
import moment from 'moment';

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(ElementUI, { locale });
Vue.use(VueSignaturePad);


Vue.http.options.xhr = { withCredentials: true };
Vue.http.options.emulateJSON = true;
Vue.http.headers.common["Token"] = settings.get_token();
Vue.http.headers.common["XF"   ] = settings.get_id_firma();

Vue.http.options.root = settings.get_base_url();

Vue.filter('HHMMSS', function (value) {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   >= 0 && hours   < 10) {hours   = "0" + hours;}
  if (minutes >= 0 && minutes < 10) {minutes = "0" + minutes;}
  if (seconds >= 0 && seconds < 10) {seconds = "0" + seconds;}
  return hours+':'+minutes+':'+seconds;
})

Vue.filter('HHMM', function (value) {
  var sec_num = parseInt(value, 10); // don't forget the second param
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   >= 0 && hours   < 10) {hours   = "0" + hours;}
  if (minutes >= 0 && minutes < 10) {minutes = "0" + minutes;}
  if (seconds >= 0 && seconds < 10) {seconds = "0" + seconds;}
  return hours+':'+minutes;
})

Vue.filter('nice_date', function (value) {
  return moment(value).format("DD MMM YYYY");
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')







