<template>
  <b-navbar>
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img src="@/assets/logo.png" alt="Pontaj" />
            </b-navbar-item>
        </template>

        <template slot="start">
            <b-navbar-item href="#/dashboard"     class='dashboard'   > Dashboard </b-navbar-item>
            <b-navbar-item href="#/angajati"        class='angajati'    > Angajati </b-navbar-item>
            <b-navbar-item href="#/zile-libere"         class='zile-libere' > Zile libere </b-navbar-item>

            <b-navbar-dropdown label="Nomenclatoare">
              <b-navbar-item href="#/centre-cost"     class='centre-cost' > Centre de cost </b-navbar-item>
              <b-navbar-item href="#/departamente"    class='departamente'> Departamente </b-navbar-item>
              <b-navbar-item href="#/programe-lucru"  class='programe-lucru'> Programe de lucru </b-navbar-item>
              <b-navbar-item href="#/tipuri-zile-libere"  class='tipuri-zile-libere'> Tipuri Zile libere </b-navbar-item>
            </b-navbar-dropdown>

            
            
            <b-navbar-dropdown label="Rapoarte">
                <b-navbar-item href="#/raport-totalizator">Raport totalizator</b-navbar-item>
                <b-navbar-item href="#/raport-concedii">Raport concedii</b-navbar-item>
            </b-navbar-dropdown>

            <b-navbar-item href="#/setari"              class='setari'      > Setari </b-navbar-item>
        </template>

        <template slot="end">
            <b-navbar-item tag="div">
                <b-select placeholder="Selecteaza firma" class='firma' v-model="idFirma" @change='onChangeFirma' style='margin-right:5px;'>
                  <option v-for="f in info.firme" :key="'firma-' + f.Id" :label="f.Nume" :value="f.Id"/>
                </b-select>
                <div class="buttons">
                    <a class="button is-primary"><strong>V.{{version}}</strong></a>
                    <a class="button is-light" @click='onLogout()'>Iesire</a>
                </div>
            </b-navbar-item>
        </template>

    </b-navbar>
</template>

<script>

import settings from '@/backend/LocalSettings';
import BaseComponent from '@/pages/BaseComponent';
import EventBus from '@/eventBus';

export default {
  name: 'Login',
  extends: BaseComponent,
  data () {
    return { 
      version:'',
      info: {
        firme: []
      },
      idFirma:'-1',
      firme:[],
    }
  },
  methods: 
  {
    
    async get_info(){
      var response  = await this.post("dashboard/get_topbar_info");
      this.info     = response;
    },
    onChangeFirma(){
      settings.set_id_firma( this.idFirma );
      
      EventBus.$emit('CHANGE_FIRMA');
    },
    async onLogout()
    {
        settings.logout();
        this.$router.push("/");
    },

  },
  mounted() {
    this.version     = settings.version;
    this.idFirma     = settings.get_id_firma();
    this.get_info();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

.navbar-menu .active {
    color: #0d7aa3;
}

</style>
