import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue'

Vue.use(VueRouter)
    
    
const routes = [
  { path: '/'                   , name: 'Login'             , component: Login },
  { path: '/dashboard'          , name: 'Dashboard'         , component: () => import('@/pages/Dashboard.vue'         ) },
  { path: '/departamente'       , name: 'Departamente'      , component: () => import('@/pages/Departamente.vue'      ) },
  { path: '/centre-cost'        , name: 'CentreCost'        , component: () => import('@/pages/CentreCost.vue'        ) },
  { path: '/programe-lucru'     , name: 'ProgrameLucru'     , component: () => import('@/pages/ProgrameLucru.vue'     ) },
  { path: '/angajati'           , name: 'Angajati'          , component: () => import('@/pages/Angajati.vue'          ) },
  { path: '/tipuri-zile-libere' , name: 'TipuriZileLibere'  , component: () => import('@/pages/TipuriZileLibere.vue'  ) },
  { path: '/zile-libere'        , name: 'ZileLibere'        , component: () => import('@/pages/ZileLibere.vue'        ) },
  { path: '/cereri-concediu'    , name: 'CereriConcediu'    , component: () => import('@/pages/CereriConcediu.vue'    ) },
  { path: '/raport-totalizator' , name: 'RaportTotalizator' , component: () => import('@/pages/RaportTotalizator.vue' ) },
  { path: '/raport-concedii'    , name: 'RaportConcedii'    , component: () => import('@/pages/RaportConcedii.vue'    ) },
  { path: '/setari'             , name: 'setari'            , component: () => import('@/pages/Setari.vue'            ) },
  { path: '/formular/:id/:tag'  , name: 'formular'          , component: () => import('@/pages/Formular_concediu.vue' ) },
  { path: '/cronCereri/:id/:tag', name: 'CronCereri'        , component: () => import('@/pages/CronCereri.vue'        ) },
]
const router = new VueRouter({
  routes
})

export default router
