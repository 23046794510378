<template lang="pug">
  div.login-bg
    div.login
      transition(name='slide-fade')
        el-card( class="box-card login-box" v-if="show" v-loading="loading" )
          el-form( @submit.prevent.native="login()" )
            div.header
              img( src="../assets/logo.png" class="img-responsive img-logo" )
              h5.card-title Login ByTimer.com
            hr
            el-form-item( label="Email" )
              el-input( type="email"  v-model="email" placeholder='Emailul dvs')
            el-form-item( label="Parola" )
              el-input( type="password" v-model="password" placeholder='Parola dvs')
            el-form-item
              el-button( type="primary" native-type='submit' ) Login
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import Vue from 'vue'

export default {
  name: "login",
  extends: BasePage,
  data () {
    return {
      show: false,
      loading: false,
      email: "",
      password: ""
    }
  },
  methods: {
    login: async function() {     
      
      Vue.http.options.root = settings.get_base_url();

      var response = await this.post('login', { email: this.email, password: this.password } );

      if( response.Token != '' ){
        settings.set_token   ( response.Token   );
        settings.set_id_firma( response.XF      );
        this.$router.push("/dashboard");
      }else{
        this.$message.error('Date gresite');
      }
    },
    check_login() {
      if( settings.is_logged_in() ){
        this.$router.push("/dashboard");
      }
    }
  },
  mounted: function() {
    this.show   = true;
  }
};
</script>

<style lang="less" scoped>
  
  .header{
    text-align: center;
  }
  .img-logo {
    max-height: 40px;
  }
  .login-bg{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;;
  }
  .login-box {
    // margin: 50px auto;
    width: 320px;
  }

  .slide-fade-enter-active {
    transition: all 1.3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(250px);
    opacity: 0;
  }
</style>
