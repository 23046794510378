<template lang="pug">
  div
</template>

<script>
import settings from "./../backend/LocalSettings";
import TopBar from '@/widgets/TopBar';

export default {
  name: "BaseComponent",
  data () {
    return { 
      loadingVisible: false
    }
  },
  methods: {
    post: async function(url, args={}){
      this.loadingVisible      = true;
      var             response = await this.$http.post(url, args );
      this.loadingVisible      = false;
      if( settings.verify_response(response) )
      {
        return response.body;
      } else {
        this.$router.push("/");
      }
    },
  },
  mounted: function() {
    
  }
};
</script>

<style scoped>
  
</style>