<template>
  <el-container>
    <el-container>
      <el-header>
        <div class='left-part'>
          <el-tooltip content="Meniu">
            <el-button @click='toggleMenu()' circle size='small' icon='el-icon-menu'/>
          </el-tooltip>
          <div class='separator'></div>
          <span class='page-title'><slot name="titlu"/></span>
          <div class='separator'></div>
          <slot name="leftmenu"/>
        </div>
        <div class='right-part hidden-xs-only'>
          <el-select v-if='firmeVisible' placeholder="Selecteaza firma" class='firma' v-model="idFirma" @change='onChangeFirma' size='small'>
              <el-option v-for="f in info.firme" :key="'firma-' + f.Id" :label="f.Nume" :value="f.Id"/>
          </el-select>
        </div>
      </el-header>
      <el-main>
        <slot/>
      </el-main>
    </el-container>
    <div class='menu-wrapper' :style="{display: menuWrapperVisible ? 'block' : 'none'}" @click='toggleMenu()'>
      <div class='menu' :style="{left: menuVisible ? '0px' : '-100%'}">
        <div class='upper'>
          <img src='@/assets/logo.png' class='logo'/>
          <span class='subtitle'>ByTimer.com</span>
        </div>
        <ul>
          <li> <a href='#/dashboard'>Dashboard</a> </li>
          <li style='margin-top: 15px'> <a href='#/angajati'>Angajati</a> </li>
          <li> <a href='#/zile-libere'>Zile libere</a> </li>
          <li> <a href='#/cereri-concediu'>Cereri Concediu</a> </li>

          <li style='margin-top: 15px'> <a href='#/centre-cost'>Centre de cost</a> </li>
          <li> <a href='#/departamente'>Departamente</a> </li>
          <li> <a href='#/programe-lucru'>Programe de lucru</a> </li>
          <li> <a href='#/tipuri-zile-libere'>Tipuri Zile libere</a> </li>

          <li style='margin-top: 15px'> <a href='#/raport-totalizator'>Raport totalizator</a> </li>
          <li> <a href='#/raport-concedii'>Raport concedii</a> </li>

          <li style='margin-top: 15px'> <a href='#/setari'>Setari</a> </li>

          <li style='margin-top: 15px'> <a href='#' @click='logout()'>Iesire cont</a> </li>

        </ul>
      </div>
    </div>
  </el-container>
</template>

<script>
import settings from "./../backend/LocalSettings";
import TopBar from '@/widgets/TopBar';
import EventBus from '@/eventBus';

export default {
  name: "BasePage",
  components: { 
      'top-bar' : TopBar
  },
  data () {
    return { 
      idFirma:'',
      loadingVisible: false,
      menuWrapperVisible: false,
      menuVisible: false,
      firmeVisible: false,

      info: {
        firme: []
      }
    }
  },
  methods: {
    post: async function(url, args={}){
      this.loadingVisible      = true;
      var             response = await this.$http.post(url, args );
      this.loadingVisible      = false;
      if( settings.verify_response(response) )
      {
        return response.body;
      } else {
        this.$router.push("/");
      }
    },

    async get_info(){
      this.firmeVisible = false;
      var response  = await this.post("dashboard/get_topbar_info");
      this.info     = response;
      this.firmeVisible = true;
    },
    
    onChangeFirma(){
      settings.set_id_firma( this.idFirma );
      EventBus.$emit('CHANGE_FIRMA');
    },

    async logout()
    {
        settings.logout();
        this.$router.push("/");
    },
    
    toggleMenu() {
      var newValue = !this.menuWrapperVisible;
      if( newValue ) {
        this.menuWrapperVisible = true;
        setTimeout(() => { this.menuVisible = true; }, 10);
      } else {
        this.menuVisible = false;
        setTimeout(() => { this.menuWrapperVisible = false; }, 150);
      }
      

    },

    async refresh_info() { },

    select_menu_item(name){
      //TODO: nu face nimic acum
    }
  },
  mounted: function() {
    this.version     = settings.version;
    this.idFirma     = settings.get_id_firma();
    this.get_info();
    EventBus.$on('CHANGE_FIRMA', payLoad => {
      this.get_info();
    });
  },
  beforeDestroy: function(){
    EventBus.$off('CHANGE_FIRMA');
  }
  
};
</script>

<style lang='less' scoped>
  .el-header {
    background-color: white;
    color: #788db4;
    height: 65px;
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 1px 1px rgba(18,106,211,.08);
    justify-content: space-between;
    align-items: center;
  }

  .left-part{
    display: flex;
    
    .page-title{
      font-size: 24px;
      margin: 0px 15px;
      min-width: 200px;
      text-align: center;
    }
  }
  .right-part{
    display: flex;
    .btn-close{
      margin-left: 5px;
    }
    
  }
  .separator{
    background-color: #ECECEC;
    height: 32px;
    width: 2px;
    margin: 0px 5px;
  }

  .menu-wrapper{
    display: none;
    background-color: fade(black, 50%);
    position: absolute;
    left: 0;
    top:0;
    width: 100vw;
    height: 100vh;
    z-index:9999;
  }

  .menu{
    position: absolute;
    left: -100%;
    top: 0;
    width: 320px;
    height: 100vh;
    background-color: fade(white,95%);
    box-shadow: 0 1px 1px 1px rgba(18,106,211,.08);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s all;
    
    .upper{
      text-align: center;
      margin-top: 15px;
      .logo{
        height: 100px;
        width: auto;
        margin: 0 auto;
      }
      .subtitle{
        display: block;
      }
    }
    
    ul{
      padding:0;
      margin:15px 0;
      li{
        list-style: none;
        a{
          text-decoration: none;
          color: #4d4f4e;
          display: block;
          text-align: left;
          font-size: 20px;
          padding: 5px 5px 5px 25px;
          // border-bottom: 1px solid rgba(77, 79, 78, 0.1);
          &:hover{
            color: black;
            // border-bottom: 1px solid rgba(77, 79, 78, 0.5);
          }
        }
      }
    }
  }
    
    
    
</style>